<template>
  <div id="root">
    <error-boundary v-cloak id="app">
      <router-view />
    </error-boundary>
  </div>
</template>

<style lang="scss">
:root {
  --color-primary: #007ecb;
  --color-darker-20: #0065af;
  --color-darker-40: #004d93;
  --color-darker-60: #003679;
  --color-darker-80: #00225f;
  --color-text-primary: #303133;
  --color-text-regular: #606266;
  --el-font-size-large: 16px;
  --el-font-size-medium: 14px;
  --el-font-size-base: 12px;
  --el-font-size-small: 11px;
  --el-font-size-extra-small: 10px;
  --el-checkbox-font-size: 12px;
  --el-select-input-font-size: 12px;
}
// elementPlusのフォントサイズをover ride
:root {
  --el-font-size-large: 16px;
  --el-font-size-medium: 14px;
  --el-font-size-base: 12px;
  --el-font-size-small: 11px;
  --el-font-size-extra-small: 10px;
  --el-checkbox-font-size: 12px;
  --el-select-input-font-size: 12px;
}
.el-checkbox {
  --el-checkbox-font-size: 12px;
}

.el-select__wrapper {
  font-size: 12px;
}

html,
body,
#root {
  font-size: 12px;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  height: 100%;
}
#app {
  overflow-y: hidden;
  height: 100%;
  > div,
  > div > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .el-table,
  .el-form-item__content {
    font-size: 1rem;
  }
  .el-form-item .el-form-item__label {
    align-items: center;
    color: var(--color-darker-80);
    opacity: 0.75;
    .el-icon-mobile-phone,
    .el-icon-warning {
      color: var(--color-text-regular);
      opacity: 1;
    }
  }
}
#header {
  flex: 0 0 auto;
}

/** wrapper of #main */
#header + .el-container {
  flex: 1 1 auto;
  overflow-y: auto;
}

.el-header {
  font-size: 1.1em;
  color: var(--color-text-primary);
  text-align: center;
  line-height: 50px;
}

#main {
  flex: 1 1 auto;
  overflow-x: auto;
  display: flex;
  padding: 0;
  > * {
    margin-left: 8px;
    margin-right: 8px;
  }
  .el-header {
    color: var(--color-text-primary);
    font-size: 1.5rem;
    text-align: left;
    padding-left: 0;
  }

  .bottom-pagination {
    margin-top: 8px;
  }

  .button-area {
    display: inline-block;
    margin-top: 8px;
  }
  .main-header {
    justify-content: space-between;
    margin: 8px;
    height: 40px !important;
  }
}

.el-main {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 70vh;
}

.el-message-box.break {
  white-space: pre-wrap;
}
</style>
