/*
 * メニュー一覧
 *
 * 許可リスト方式
 * 全てのロールに許可する画面は "all" を指定
 */
import Config from "@/conf/Config";

export default [
  {
    category: "RESERVATION",
    order: 1,
    id: "RESERVATION_CALENDER",
    name: "予約カレンダー",
    url: "/scheduleList",
    roles: [
      Config.ROLE.ADMIN,
      Config.ROLE.CARSHARE_CORP,
      Config.ROLE.CARSHARE_USE_CORP,
      Config.ROLE.CLEANING_CORP,
      Config.ROLE.MAINTENANCE_CORP
    ]
  },
  {
    category: "RESERVATION",
    order: 2,
    id: "RESERVATION_LIST",
    name: "予約一覧",
    url: "/reservationList",
    roles: [
      Config.ROLE.ADMIN,
      Config.ROLE.CARSHARE_CORP,
      Config.ROLE.CARSHARE_USE_CORP,
      Config.ROLE.CLEANING_CORP,
      Config.ROLE.MAINTENANCE_CORP
    ]
  },
  {
    category: "USER",
    order: 1,
    id: "USER_LIST",
    name: "ユーザ一覧",
    url: "/userList",
    roles: [Config.ROLE.ALL]
  },
  {
    category: "USER",
    order: 2,
    id: "USER_REGISTER",
    name: "ユーザ登録",
    url: "/userEdit",
    roles: [Config.ROLE.ALL]
  },
  {
    category: "CORPORATION",
    order: 1,
    id: "CORPORATION_LIST",
    name: "法人一覧",
    url: "/corporationList",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP, Config.ROLE.ENERGY_MANAGEMENT_CORP]
  },
  {
    category: "CORPORATION",
    order: 2,
    id: "MANAGE_CORP_REGISTER",
    name: "事業者登録",
    url: "/corporationEdit?corpType=businessCorp",
    roles: [Config.ROLE.ADMIN]
  },
  {
    category: "CORPORATION",
    order: 3,
    id: "SERVICE_PROVIDE_CORP_REGISTER",
    name: "サービス提供法人登録",
    url: "/corporationEdit?corpType=provideCorp",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP]
  },
  {
    category: "CORPORATION",
    order: 4,
    id: "SERVICE_USE_CORP_REGISTER",
    name: "サービス利用法人登録",
    url: "/corporationEdit?corpType=carshare",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP]
  },
  {
    category: "CORPORATION",
    order: 5,
    id: "CONTACT_MANAGEMENT_CORP_REGISTER",
    name: "運用連絡先登録",
    url: "/corporationEdit?corpType=contactManagement",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP, Config.ROLE.ENERGY_MANAGEMENT_CORP]
  },
  {
    category: "CORPORATION",
    order: 6,
    id: "CORP_FRAME_CYCLE_LIST",
    name: "法人枠作成サイクル一覧",
    url: "/corpFrameCycleList",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP]
  },
  {
    category: "CORPORATION",
    order: 7,
    id: "CORPORATION_FRAME_CYCLE_REGISTER",
    name: "法人枠作成サイクル登録",
    url: "/corpFrameCycleEdit",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP]
  },
  {
    category: "EV",
    order: 1,
    id: "EV_LIST",
    name: "ステーション一覧",
    url: "/evList",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP, Config.ROLE.ENERGY_MANAGEMENT_CORP]
  },
  {
    category: "EV",
    order: 2,
    id: "EV_REGISTER",
    name: "ステーション登録",
    url: "/evEdit",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP, Config.ROLE.ENERGY_MANAGEMENT_CORP]
  },
  {
    category: "MEMBER",
    order: 1,
    id: "MEMBER_LIST",
    name: "会員一覧",
    url: "/memberList",
    roles: [
      Config.ROLE.ADMIN,
      Config.ROLE.CARSHARE_CORP,
      Config.ROLE.CARSHARE_USE_CORP,
      Config.ROLE.CLEANING_CORP,
      Config.ROLE.MAINTENANCE_CORP
    ]
  },
  {
    category: "MEMBER",
    order: 3,
    id: "MEMBER_INVITATION",
    name: "法人会員招待メール送信",
    url: "/memberInvite",
    roles: [
      Config.ROLE.ADMIN,
      Config.ROLE.CARSHARE_CORP,
      Config.ROLE.CARSHARE_USE_CORP,
      Config.ROLE.CLEANING_CORP,
      Config.ROLE.MAINTENANCE_CORP
    ]
  },
  {
    category: "MEMBER",
    order: 4,
    id: "GROUP_LIST",
    name: "グループ一覧",
    url: "/groupList",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP]
  },
  {
    category: "MEMBER",
    order: 5,
    id: "GROUP_REGISTER",
    name: "グループ登録",
    url: "/groupEdit",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP]
  },
  {
    category: "CAR",
    order: 1,
    id: "VEHICLE_LIST",
    name: "車両一覧",
    url: "/vehicleList",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP, Config.ROLE.CARSHARE_USE_CORP, Config.ROLE.CLEANING_CORP, Config.ROLE.MAINTENANCE_CORP, Config.ROLE.ENERGY_MANAGEMENT_CORP]
  },
  {
    category: "CAR",
    order: 2,
    id: "VEHICLE_REGISTER",
    name: "車両登録",
    url: "/vehicleEdit",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP, Config.ROLE.ENERGY_MANAGEMENT_CORP]
  },
  {
    category: "PROMOTION",
    order: 1,
    id: "PROMOTION_LIST",
    name: "プロモーション一覧",
    url: "/promotionList",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP]
  },
  {
    category: "PROMOTION",
    order: 2,
    id: "PROMOTION_REGISTER",
    name: "プロモーション登録",
    url: "/promotionEdit",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP]
  },
  {
    category: "NOTIFICATION",
    order: 1,
    id: "NOTIFICATION_SETTING_LIST",
    name: "お知らせ管理一覧",
    url: "/notificationSettingList",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP]
  },
  {
    category: "NOTIFICATION",
    order: 2,
    id: "NOTIFICATION_SETTING_REGISTER",
    name: "お知らせ設定登録",
    url: "/notificationSettingEdit",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP]
  },
  {
    category: "FILE",
    order: 1,
    id: "SHARED_FILE_DOWNLOAD",
    name: "ファイルダウンロード",
    url: "/sharedFileDownload",
    roles: [Config.ROLE.ALL]
  },
  // {
  //   category: "FILE",
  //   order: 2,
  //   id: "SHARED_FILE_UPLOAD",
  //   name: "ファイルアップロード",
  //   url: "/sharedFileUpload",
  //   roles: [Config.ROLE.ALL]
  // }
];
