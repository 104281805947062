import { sprintf } from "sprintf-js";
import store, { MUTATION_TYPES } from "@/store";

export default class ErrorHandler {
  static async apiHandleErr(error) {
    try {
      const { response } = error;

      // レスポンスが空であるかどうかを確認
      if (!response || !response.body) {
        console.error("Empty response");
        return;
      }

      // レスポンスのボディがすでに読み取られているかどうかを確認
      if (response.bodyUsed) {
        console.error("Response body stream already read");
        return;
      }

      // レスポンスのボディをテキストとして取得
      const text = await response.text();

      // JSON をパース
      const json = JSON.parse(text);

      if (response.status === 400) {
        if (json.fieldErrors && json.fieldErrors.length > 0) {
          const messages = ErrorHandler.createMessage(json.fieldErrors);
          store.commit(MUTATION_TYPES.SET_ERROR_MESSAGES, messages);
        } else if (json.message) {
          store.commit(MUTATION_TYPES.SET_ERROR_MESSAGES, [json.message]);
        }
      } else {
        throw new Error(error);
      }
    } catch (e) {
      console.error("Error handling:", e);
      throw new Error(e);
    }
  }

  // static async apiHandleErr(error) {
  //   try {
  //     const { response } = error;
  //     const json = await response.json();
  //     if (response.status === 400) {
  //       if (json.fieldErrors && json.fieldErrors.length > 0) {
  //         const messages = ErrorHandler.createMessage(json.fieldErrors);
  //         store.commit(MUTATION_TYPES.SET_ERROR_MESSAGES, messages);
  //       } else if (json.message) {
  //         store.commit(MUTATION_TYPES.SET_ERROR_MESSAGES, [json.message]);
  //       }
  //     } else {
  //       throw new Error(error);
  //     }
  //   } catch (e) {
  //     throw new Error(e);
  //   }
  // }

  static createMessage(fieldErrors) {
    if (!fieldErrors || fieldErrors.length === 0) return [];
    const messages = [];
    fieldErrors.forEach((fieldError) => {
      const { fieldName, errorMessage } = fieldError; // eslint-disable-line
      if (fieldName && errorMessage) {
        // eslint-disable-line
        const errorMessageBase = errorMessage.replace("{0}", "%s"); // eslint-disable-line
        messages.push(sprintf(errorMessageBase, fieldName)); // eslint-disable-line
      }
    });
    return messages;
  }
}
