<template>
  <div>
    <app-header></app-header>

    <el-container>
      <app-navbar></app-navbar>

      <el-main id="main">
        <el-header class="main-header">
          <!-- setting title -->
          <slot name="title"></slot>
        </el-header>

        <!-- setting content -->
        <slot name="content"></slot>

        <app-footer></app-footer>
      </el-main>
    </el-container>
  </div>
</template>

<script>
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-main >>> .group-title {
  color: var(--color-darker-60);
  text-align: left;
  margin-top: 10px;
  margin-left: 20px;
  font-size: 1.25rem;
}
</style>
