import * as Sentry from '@sentry/browser';

export default class ErrorTracking {
  static configureScope(session) {
    Sentry.configureScope((scope) => {
      scope.setTag('carshareCorpId', session.belongCorpId)
      scope.setUser({
        id: session.id,
        username: `${session.firstName} ${session.lastName}`,
        email: session.email,
      });
    });
  }

  static captureException(err) {
    Sentry.captureException(err);
  }

  static showReportDialog() {
    Sentry.showReportDialog();
  }

  static captureMessage(message) {
    Sentry.captureMessage(message);
  }
}
