<template>
  <el-menu
    v-if="isLogin && !needMFA"
    id="menu"
    default-active="1"
    class="el-menu-vertical-demo"
    :collapse="isCollapse"
  >
    <el-sub-menu v-for="(c, i) in menuCategories" :key="i" :index="getMenuIndex(i)">
      <template #title>
        <font-awesome-icon :icon="c.icon" size="lg" />
        <span>{{ c.name }}</span>
      </template>
      <!-- eslint-disable vue/no-use-v-if-with-v-for -->
      <template v-for="(menu, j) in menus">
        <el-menu-item
          v-if="c.category === menu.category"
          :key="j"
          :index="getSubMenuIndex(i, j)"
          @click="go(menu.url)"
        >
          <span>
            {{ menu.name }}
          </span>
        </el-menu-item>
      </template>
    </el-sub-menu>
  </el-menu>
</template>

<script>
import { isEmpty } from "@/helpers/validators";

export default {
  name: "NavbarLayout",
  computed: {
    needMFA() {
      return this.session.adminMfaEnabled && isEmpty(this.session.phoneNumber);
    },
  },

  // data() {
  //   return {
  //     /* 子メニューのデザイン（文字を除く）を調整するcss */
  //     /* 通常のcssの適用方法では、メニューを閉じた状態でメニューカテゴリをクリックした際に */
  //     /* 出現するポップアップのメニューアイテムにcssが適用されないため、dataに定義 */
  //     menuItemCss: {
  //       backgroundColor: "#305496",
  //       border: "solid",
  //       borderWidth: "1px",
  //       borderColor: "#606266",
  //       marginBottom: "-1px"
  //     },
  //     /* 子メニューの文字を調整するcss */
  //     /* 上記プロパティと同様の理由でdataに定義 */
  //     bottunCss: { color: "#ffffff", verticalAlign: "25%" }
  //   };
  // },

  methods: {
    getMenuIndex: (i) => String(i + 1),
    getSubMenuIndex: (i, j) => `${String(i + 1)}-${String(j + 1)}`,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* 親メニューのアイコンの位置を調整するcss */
#menu .el-submenu__title svg {
  margin-right: 8px;
}

.el-popper .el-menu-item span,
.el-sub-menu .el-menu-item span {
  color: #409eff;
}

.el-sub-menu .el-menu-item {
  background-color: #ebeef5;
}

/* 親メニューのデザイン（アコーディオンの矢印除く）を調整するcss */
/* #menu >>> .el-submenu__title {
  background-color: #5b9bd5;
  color: #303133;
  border: solid;
  border-width: 1px;
  border-color: #c0c4cc;
  margin: -1px -1px -1px -1px;
} */

/* アコーディオンの矢印を調整するcss */
/* #menu >>> .el-submenu__icon-arrow {
  color: #303133;
} */

#menu,
#menu .el-submenu li {
  background-color: #ebeef5;
}

#menu .svg-inline--fa {
  padding-right: 8px;
}

/* サイドメニューを開いた際の表示を調整するcss */
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 210px;
  min-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-shrink: 0;
}

.el-button--text {
  text-align: left;
  white-space: pre;
}
</style>
