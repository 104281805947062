import { createApp } from "vue";

// add Font awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// for event logging
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

import ElementPlus from "element-plus";
import locale from "element-plus/dist/locale/ja.mjs";
import "element-plus/dist/index.css";
import "@/assets/theme/element-variables.scss";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import router from "@/router";
import store from "@/store";
import "./registerServiceWorker";

// add plugin for global
import MyApplicationPlugin from "@/module/plugin/application";

// add filter for global
// import filter from "@/module/filter";

// add directive for global
import directive from "@/module/directive";

// add compornent for global
import ErrorBoundary from "@/components/ErrorBoundary.vue";
import ErrorArea from "@/components/ErrorArea.vue";
import NavBar from "@/components/NavbarLayout.vue";
import Header from "@/components/HeaderLayout.vue";
import Footer from "@/components/FooterLayout.vue";
import logger from "@/module/Logger";
import App from "./App.vue";

const app = createApp(App);
app.use(router);
app.use(store);

app.use(ElementPlus, { locale });
// eslint-disable-next-line no-restricted-syntax
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
// vue3廃止
// app.config.productionTip = false;
app.use(MyApplicationPlugin);

app.directive("focus", directive.focus);

library.add(fas, far, fab);
app.component("FontAwesomeIcon", FontAwesomeIcon);
app.component("ErrorBoundary", ErrorBoundary);
app.component("ErrorArea", ErrorArea);
app.component("AppNavbar", NavBar);
app.component("AppHeader", Header);
app.component("AppFooter", Footer);

app.config.errorHandler = (err, vm, info) => {
  logger.error("errorHandlererr:", err);
  logger.error("errorHandlervm:", vm);
  logger.error("errorHandlerinfo:", info);
};

router.isReady().then(() => app.mount("#app"));
// app.mount("#app");

/* global SENTRY_ENDPOINT, DD_DOGS_TOKNEN */
if (process.env.NODE_ENV === "production") {
  logger.info("Loading... ############  Sentry integration enabled. ############");

  Sentry.init({
    app,
    dsn: SENTRY_ENDPOINT || "https://fa810c83b6794b7e9b6c2f8c858d550a@sentry.io/1759472",
    integrations: [
      new Integrations.Vue({
        attachProps: true,
      }),
    ],
  });

  logger.info("Loading... ############  Datadog integration enabled. ############");

  // datadog廃止
  global.DD_LOGS = {
    logger: {
      info: (message) => console.log(message),
    },
    init: () => {},
    addLoggerGlobalContext: () => {},
  };

  // Set your DataDog client token
  // eslint-disable-next-line
  DD_LOGS.init({
    clientToken: DD_DOGS_TOKNEN || "pubd4810b97d83909ccb893b00bfc6a330a",
    forwardErrorsToLogs: true,
  });

  // eslint-disable-next-line
  DD_LOGS.addLoggerGlobalContext("AppType", "management");
}

window.addEventListener("error", (e) => logger.info(e));
