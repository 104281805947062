import is from "is_js";
import store, { GETTER_TYPES } from "@/store";
import queryString from "query-string";
import { SESSION_GETTER_TYPES } from "@/store/modules/session";

class Api {
  /** *******************
   * Get Settings
   * ****************** */
  static makeGetUrl = (base, where) =>
    base + (is.empty(where) ? "" : `?${queryString.stringify(where).toString()}`);

  static getHeader = () => ({
    "X-App-Type": "management", // app type
    "X-User-Id": store.getters[SESSION_GETTER_TYPES.VALUES].id, // user id
    "X-Carshare_Corp-Id": store.getters[SESSION_GETTER_TYPES.VALUES].belongCorpId, // carshare corp id
    "X-Cognito-Id": store.getters[SESSION_GETTER_TYPES.VALUES].sub, // cognito id
    "X-Refresh-Token": store.getters[GETTER_TYPES.GET_REFRESH_TOKEN], // refresh token
    "Content-Type": "application/json",
  });

  // get filter
  /* filter implements */
  static searchEnd = (response) => response;

  static checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  };

  static toJson = (response) => response.json();

  static toText = (response) => response.text();

  static fetchGet = (base, where = {}) =>
    window
      .fetch(this.makeGetUrl(base, where), {
        method: "GET",
        headers: this.getHeader(),
      })
      .then(this.searchEnd)
      .then(this.checkStatus);

  /** *******************
   * Post/Put Settings
   * ****************** */
  static submitButton = () => document.querySelectorAll(".button");

  static updateHeader = () => ({
    "X-Requested-With": "csrf", // csrf header
    "X-App-Type": "management", // app type
    "X-User-Id": store.getters[SESSION_GETTER_TYPES.VALUES].id, // user id
    "X-Carshare_Corp-Id": store.getters[SESSION_GETTER_TYPES.VALUES].belongCorpId, // carshare corp id
    "X-Cognito-Id": store.getters[SESSION_GETTER_TYPES.VALUES].sub, // cognito id
    "X-Refresh-Token": store.getters[GETTER_TYPES.GET_REFRESH_TOKEN], // refresh token
    "Content-Type": "application/json",
  });

  static updateFileHeader = () => ({
    "X-Requested-With": "csrf", // csrf header
    "X-App-Type": "management", // app type
    "X-User-Id": store.getters[SESSION_GETTER_TYPES.VALUES].id, // user id
    "X-Carshare_Corp-Id": store.getters[SESSION_GETTER_TYPES.VALUES].belongCorpId, // carshare corp id
    "X-Cognito-Id": store.getters[SESSION_GETTER_TYPES.VALUES].sub, // cognito id
    "X-Refresh-Token": store.getters[GETTER_TYPES.GET_REFRESH_TOKEN], // refresh token
  });

  /* filter implements */
  static updateStartFilter = () => {};

  /* filter implements */
  static updateEndFilter = () => {};

  static updatehEnd = (response) => {
    this.updateEndFilter();
    return response;
  };

  static postBase = (url, data) => {
    this.updateStartFilter();
    return window.fetch(url, {
      method: "POST",
      headers: this.updateHeader(),
      body: JSON.stringify(data),
    });
  };

  static postFile = (url, data) => {
    this.updateStartFilter();
    return window.fetch(url, {
      method: "POST",
      headers: this.updateFileHeader(),
      body: data,
    });
  };

  static putBase = (url, data) => {
    this.updateStartFilter();
    return window.fetch(url, {
      method: "PUT",
      headers: this.updateHeader(),
      body: JSON.stringify(data),
    });
  };

  static fetchPost = (url, data) =>
    this.postBase(url, data).then(this.updatehEnd).then(this.checkStatus);

  static fetchPostFile = (url, data) =>
    this.postFile(url, data).then(this.updatehEnd).then(this.checkStatus);

  static fetchPut = (url, data) =>
    this.putBase(url, data).then(this.updatehEnd).then(this.checkStatus);

  /** *******************
   * Delete Settings
   * ****************** */
  static fetchDelete = (url) =>
    window
      .fetch(url, {
        method: "DELETE",
        headers: this.updateHeader(),
      })
      .then(this.checkStatus);
}

const API_CONFIG = {};
API_CONFIG.HOST = window.location.origin;
API_CONFIG.BASE_URL = `${API_CONFIG.HOST}/admin`;
API_CONFIG.BASE_URL_COMMON = `${API_CONFIG.BASE_URL}/api/common`;
API_CONFIG.ENDPOINT_LOG = `${API_CONFIG.BASE_URL}/api/log`;
API_CONFIG.ENDPOINT_USER = `${API_CONFIG.BASE_URL}/api/user`;
API_CONFIG.ENDPOINT_CODE = `${API_CONFIG.BASE_URL}/api/code`;
API_CONFIG.ENDPOINT_TRANSLATE = `${API_CONFIG.BASE_URL}/api/translate`;
API_CONFIG.ENDPOINT_CORPORATION = `${API_CONFIG.BASE_URL}/api/corporation`;
API_CONFIG.ENDPOINT_CORPORATION_FRAME = `${API_CONFIG.BASE_URL}/api/corporation_frame`;
API_CONFIG.ENDPOINT_CORP_FRAME_CYCLE = `${API_CONFIG.BASE_URL}/api/corp_frame_cycle`;
API_CONFIG.ENDPOINT_EV = `${API_CONFIG.BASE_URL}/api/evstation`;
API_CONFIG.ENDPOINT_VEHICLE = `${API_CONFIG.BASE_URL}/api/vehicle`;
API_CONFIG.ENDPOINT_SESSION = `${API_CONFIG.BASE_URL}/api/session/management`;
API_CONFIG.ENDPOINT_MEMBER = `${API_CONFIG.BASE_URL}/api/members`;
API_CONFIG.ENDPOINT_MEMBER_UNSUBSCRIBE = `${API_CONFIG.BASE_URL}/api/member_unsubscribe`;
API_CONFIG.ENDPOINT_CHARGE_PLAN = `${API_CONFIG.BASE_URL}/api/charge_plan`;
API_CONFIG.ENDPOINT_SCHEDULE = `${API_CONFIG.BASE_URL}/api/reservation/calendar`;
API_CONFIG.ENDPOINT_ZIP_CODE = `${API_CONFIG.BASE_URL}/api/zip_code`;
API_CONFIG.ENDPOINT_DOWNLOAD_IMAGE = `${API_CONFIG.BASE_URL}/api/private_download_image`;
API_CONFIG.ENDPOINT_RESERVATION = `${API_CONFIG.BASE_URL}/api/reservation`;
API_CONFIG.ENDPOINT_UPLOAD_IMAGE = `${API_CONFIG.BASE_URL}/api/upload_public_image`;
API_CONFIG.ENDPOINT_RESERVATION_START = `${API_CONFIG.BASE_URL}/api/reservation_start`;
API_CONFIG.ENDPOINT_RESERVATION_STOP = `${API_CONFIG.BASE_URL}/api/reservation_stop`;
API_CONFIG.ENDPOINT_RESERVATION_CANCEL = `${API_CONFIG.BASE_URL}/api/reservation_cancel`;
API_CONFIG.ENDPOINT_RESERVATION_EXTENTION_TIME = `${API_CONFIG.BASE_URL}/api/reservation_extention_time`;
API_CONFIG.ENDPOINT_QUESTIONNAIRE = `${API_CONFIG.BASE_URL}/api/usage_questionnaire`;
API_CONFIG.ENDPOINT_REENERGY = `${API_CONFIG.BASE_URL}/api/renewable_energy_ratio`;
API_CONFIG.ENDPOINT_CHARGE = `${API_CONFIG.BASE_URL}/api/charge`;
API_CONFIG.ENDPOINT_INSURANCE = `${API_CONFIG.BASE_URL}/api/insurance`;
API_CONFIG.ENDPOINT_PROMOTION = `${API_CONFIG.BASE_URL}/api/promotion`;
API_CONFIG.ENDPOINT_GROUP = `${API_CONFIG.BASE_URL}/api/group`;
API_CONFIG.ENDPOINT_NOTIFICATION_SETTING = `${API_CONFIG.BASE_URL}/api/notification_setting`;
API_CONFIG.ENDPOINT_SHARED_FILE_LIST = `${API_CONFIG.BASE_URL}/api/shared_file_list`;
API_CONFIG.ENDPOINT_DOWNLOAD_SHARED_FILE = `${API_CONFIG.BASE_URL}/api/shared_file`;

/** *******************
 * API Settings
 * ****************** */
export const logApi = {
  access: (path) => Api.fetchGet(`${API_CONFIG.ENDPOINT_LOG}/access`, path),
  error: (data) => Api.fetchPost(`${API_CONFIG.ENDPOINT_LOG}/error`, data),
};

export const masterApi = {
  getCodeCategory: () => Api.fetchGet(`${API_CONFIG.BASE_URL}/api/codeCategory`).then(Api.toJson),
};

export const userApi = {
  findAll: (where) => Api.fetchGet(`${API_CONFIG.ENDPOINT_USER}/list`, where).then(Api.toJson),
  findById: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_USER}/${id}`).then(Api.toJson),
  create: (data) => Api.fetchPost(API_CONFIG.ENDPOINT_USER, data).then(Api.toJson),
  /* data.idはuserIdを指す */
  update: (data) => Api.fetchPut(`${API_CONFIG.ENDPOINT_USER}/${data.id}`, data).then(Api.toJson),
  delete: (id) => Api.fetchDelete(`${API_CONFIG.ENDPOINT_USER}/${id}`).then(Api.toJson),
  changePassword: (data) =>
    Api.fetchPost(`${API_CONFIG.ENDPOINT_USER}/updatePassword`, data).then(Api.toJson),
  getCorpName: (id, isBelongCorp) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_USER}/corp_entry_info/${id}`, isBelongCorp).then(
      Api.toJson
    ),
  resetPhoneNumber: (userId) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_USER}/reset_phone_number/${userId}`).then(Api.toJson),
};

export const codeApi = {
  findAll: (where) => Api.fetchGet(API_CONFIG.ENDPOINT_CODE, where).then(Api.toJson),
  findById: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_CODE}/${id}`).then(Api.toJson),
  create: (data) => Api.fetchPost(API_CONFIG.ENDPOINT_CODE, data).then(Api.toJson),
  update: (data) => Api.fetchPut(API_CONFIG.ENDPOINT_CODE, data).then(Api.toJson),
  delete: (id) => Api.fetchDelete(`${API_CONFIG.ENDPOINT_CODE}/${id}`).then(Api.toJson),
};

export const translateApi = {
  translateEnglish: (word) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_TRANSLATE}/en?text=${word}`).then(Api.toText),
};
export const corporationApi = {
  findAll: (where) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_CORPORATION}/list`, where).then(Api.toJson),
  findById: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_CORPORATION}/${id}`).then(Api.toJson),
  getCorpName: (id) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_CORPORATION}/corp_entry_info/${id}`).then(Api.toJson),
  create: (data) => Api.fetchPost(API_CONFIG.ENDPOINT_CORPORATION, data).then(Api.toJson),
  update: (data) => Api.fetchPut(API_CONFIG.ENDPOINT_CORPORATION, data).then(Api.toJson),
  cancelCorpTyingAll: (id, data) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_CORPORATION}/corp_tying_all/${id}`, data).then(Api.toJson),
};
export const corporationFrameApi = {
  findById: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_CORPORATION_FRAME}/${id}`).then(Api.toJson),
  create: (data) => Api.fetchPost(API_CONFIG.ENDPOINT_CORPORATION_FRAME, data).then(Api.toJson),
  update: (data) => Api.fetchPut(API_CONFIG.ENDPOINT_CORPORATION_FRAME, data).then(Api.toJson),
  delete: (id) =>
    Api.fetchDelete(`${API_CONFIG.ENDPOINT_CORPORATION_FRAME}/${id}`).then(Api.toJson),
};
export const corpFrameCycleApi = {
  findAll: (where) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_CORP_FRAME_CYCLE}/list`, where).then(Api.toJson),
  findById: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_CORP_FRAME_CYCLE}/${id}`).then(Api.toJson),
  create: (data) => Api.fetchPost(API_CONFIG.ENDPOINT_CORP_FRAME_CYCLE, data).then(Api.toJson),
  update: (data) => Api.fetchPut(API_CONFIG.ENDPOINT_CORP_FRAME_CYCLE, data).then(Api.toJson),
  delete: (id) => Api.fetchDelete(`${API_CONFIG.ENDPOINT_CORP_FRAME_CYCLE}/${id}`).then(Api.toJson),
};
export const evApi = {
  findAll: (where) => Api.fetchGet(`${API_CONFIG.ENDPOINT_EV}/list`, where).then(Api.toJson),
  findById: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_EV}/detail/${id}`).then(Api.toJson),
  create: (data) => Api.fetchPost(API_CONFIG.ENDPOINT_EV, data).then(Api.toJson),
  update: (data) => Api.fetchPut(API_CONFIG.ENDPOINT_EV, data).then(Api.toJson),
  delete: (id) => Api.fetchDelete(`${API_CONFIG.ENDPOINT_EV}/${id}`).then(Api.toJson),
  sendOpenNotice: (id, version) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_EV}/send_open_notice/${id}`, version).then(Api.toJson),
  sendCloseNotice: (id, version) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_EV}/send_close_notice/${id}`, version).then(Api.toJson),
  findCarshareUseCorpListByType: (id, type) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_EV}/corp_list/${id}/${type}`).then(Api.toJson),
  upload: (file) => Api.fetchPostFile(API_CONFIG.ENDPOINT_UPLOAD_IMAGE, file).then(Api.toJson),
  findChargeTypeList: () =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_EV}/charger_type_list`).then(Api.toJson),
};

export const vehicleApi = {
  findById: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_VEHICLE}/detail/${id}`).then(Api.toJson),
  findAll: (where) => Api.fetchGet(`${API_CONFIG.ENDPOINT_VEHICLE}/list`, where).then(Api.toJson),
  create: (data) => Api.fetchPost(API_CONFIG.ENDPOINT_VEHICLE, data).then(Api.toJson),
  update: (data) => Api.fetchPut(API_CONFIG.ENDPOINT_VEHICLE, data).then(Api.toJson),
  delete: (id) => Api.fetchDelete(`${API_CONFIG.ENDPOINT_VEHICLE}/${id}`).then(Api.toJson),
  findCarshareUseCorpListByType: (id, type) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_VEHICLE}/corp_list/${id}/${type}`).then(Api.toJson),
  getVehicfindVehicleTypeList: (id) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_VEHICLE}/vehicle_type_list/${id}`).then(Api.toJson),
  changeAlertStatus: (data) =>
    Api.fetchPost(`${API_CONFIG.ENDPOINT_VEHICLE}/changeAlertStatus`, data).then(Api.toJson),
  getEmptyCharger: (where) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_VEHICLE}/emptyCharger`, where).then(Api.toJson),
  getLastCondition: (id) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_VEHICLE}/lastCondition/${id}`).then(Api.toJson),
  getQuestionnaireHistory: (id) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_VEHICLE}/questionnaireHistory/${id}`).then(Api.toJson),
  changeVehicleStatus: (data) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_VEHICLE}/changeCondition`, data).then(Api.toJson),
};

export const sessionApi = {
  start: (sub) => Api.fetchGet(`${API_CONFIG.ENDPOINT_SESSION}/${sub}`).then(Api.toJson),
};

export const memberApi = {
  findAll: (where) => Api.fetchGet(`${API_CONFIG.ENDPOINT_MEMBER}/list`, where).then(Api.toJson),
  findById: (id, carshareCorpId) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_MEMBER}/${id}`, carshareCorpId).then(Api.toJson),
  create: (data) => Api.fetchPost(API_CONFIG.ENDPOINT_MEMBER, data).then(Api.toJson),
  update: (id, data) => Api.fetchPut(`${API_CONFIG.ENDPOINT_MEMBER}/${id}`, data).then(Api.toJson),
  delete: (id) => Api.fetchDelete(`${API_CONFIG.ENDPOINT_MEMBER}/${id}`).then(Api.toJson),
  getMonthlyCharge: (where) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_MEMBER}/monthly_charge`, where).then(Api.toJson),
  /* dataの中身はカーシェア事業者ID */
  cancelCorpTying: (id, data) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_MEMBER}/corp_tying/${id}`, data).then(Api.toJson),
  unSubscribe: (id, carshareCorpId, data) =>
    Api.fetchPut(
      `${API_CONFIG.ENDPOINT_MEMBER_UNSUBSCRIBE}/admin/${id}/${carshareCorpId}`,
      data
    ).then(Api.toJson),
  forcedUnSubscribe: (id, carshareCorpId, data) =>
    Api.fetchPut(
      `${API_CONFIG.ENDPOINT_MEMBER_UNSUBSCRIBE}/admin_force/${id}/${carshareCorpId}`,
      data
    ).then(Api.toJson),
  suspension: (id, carshareCorpId, data) =>
    Api.fetchPut(
      `${API_CONFIG.ENDPOINT_MEMBER_UNSUBSCRIBE}/admin_suspension/${id}/${carshareCorpId}`,
      data
    ).then(Api.toJson),
  unSuspension: (id, carshareCorpId) =>
    Api.fetchPut(
      `${API_CONFIG.ENDPOINT_MEMBER_UNSUBSCRIBE}/admin_unsuspension/${id}/${carshareCorpId}`
    ).then(Api.toJson),
  /* dataの中身は変更後の審査ステータスID */
  updateExaminationInfo: (id, data) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_MEMBER}/examination_info/${id}`, data).then(Api.toJson),
  downloadCsv: (where) => Api.makeGetUrl(`${API_CONFIG.ENDPOINT_MEMBER}/csv`, where),
  invite: (data) => Api.fetchPost(`${API_CONFIG.ENDPOINT_MEMBER}/invite`, data).then(Api.toJson),
  checkChargedReservationExist: (id, carshareCorpId) =>
    Api.fetchGet(
      `${API_CONFIG.ENDPOINT_MEMBER}/exist_charged_reservation/${id}`,
      carshareCorpId
    ).then(Api.toJson),
};

export const chargePlanApi = {
  findAll: (where) => Api.fetchGet(API_CONFIG.ENDPOINT_CHARGE_PLAN, where).then(Api.toJson),
  findById: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_CHARGE_PLAN}/${id}`).then(Api.toJson),
};

export const scheduleApi = {
  findAll: (where) => Api.fetchGet(API_CONFIG.ENDPOINT_SCHEDULE, where).then(Api.toJson),
  findById: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_SCHEDULE}/${id}`).then(Api.toJson),
};

export const zipCodeApi = {
  findByZipCode: (data) => Api.fetchGet(`${API_CONFIG.ENDPOINT_ZIP_CODE}`, data).then(Api.toJson),
};

export const commonApi = {
  findEvstationList: (where) =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/evstation_list`, where).then(Api.toJson),
  findVehicleTypeList: (where) =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/vehicle_type_list`, where).then(Api.toJson),
  findCarshareCorpList: () =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/corp_list`).then(Api.toJson),
  findManageCorpList: () =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/manage_corp_list`).then(Api.toJson),
  findManageCorpOptList: () =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/manage_corp_list_options`).then(Api.toJson),
  findCarshareUseCorpList: (id) =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/corp_list/${id}`).then(Api.toJson),
  findCarshareUseCorpListByType: (id, type) =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/corp_list/${id}/${type}`).then(Api.toJson),
  findInfoManageCorpList: (id) =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/info_manage_corp_list/${id}`).then(Api.toJson),
  findAllCarshareUseCorpList: () =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/all_carshare_use_corp_list`).then(Api.toJson),
  findOnlyCarshareUseCorpList: (vehicleId) =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/carshare_use_corp_list/${vehicleId}`).then(
      Api.toJson
    ),
  findEnergyManagementCorpList: () =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/energy_management_corp_list`).then(Api.toJson),
  findManageCorpForSuggestList: (id) =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/suggest_manage_corp_list/${id}`).then(Api.toJson),
  findCorpFrameAvailableMemberList: (data) =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/corp_frame_available_member_list`, data).then(
      Api.toJson
    ),
  findVehicleTypeColorList: (where) =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/vehicle_type_color_list`, where).then(Api.toJson),
  findVehicleTypeGradeList: (where) =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/vehicle_type_grade_list`, where).then(Api.toJson),
  getCorpParam: (carshareCorpId) =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/carshare_corp_param`, {
      carshareCorpId,
    }).then(Api.toJson),
  findDepartmentForSuggestList: (where) =>
    Api.fetchGet(`${API_CONFIG.BASE_URL_COMMON}/department_list`, where).then(Api.toJson),
};

export const downloadImageApi = {
  downloadImage: (where) =>
    Api.fetchGet(API_CONFIG.ENDPOINT_DOWNLOAD_IMAGE, where).then(Api.toJson),
};

export const reservationApi = {
  findList: (where) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_RESERVATION}/list`, where).then(Api.toJson),
  downloadCsv: (where) => Api.makeGetUrl(`${API_CONFIG.ENDPOINT_RESERVATION}/csv`, where),
  findLabel: (where) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_RESERVATION}/label`, where).then(Api.toJson),
  findById: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_RESERVATION}/${id}`).then(Api.toJson),
  findVehicleById: (id) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_RESERVATION}/vehicle/${id}`).then(Api.toJson),
  findAvailability: (where) =>
    Api.fetchPost(`${API_CONFIG.ENDPOINT_RESERVATION}/availability`, where).then(Api.toJson),
  updateReservation: (id, where) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_RESERVATION}/${id}`, where).then(Api.toJson),
  updateFixedUsingDatetime: (id, where) =>
    Api.fetchPut(
      `${API_CONFIG.ENDPOINT_RESERVATION}/update_fixed_using_datetime/${id}`,
      where
    ).then(Api.toJson),
  updatePassengers: (id, data) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_RESERVATION}/update_passengers/${id}`, data),
  updateRemarks: (id, data) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_RESERVATION}/update_remarks/${id}`, data).then(Api.toJson),
  updateAlcoholCheckBefore: (id, path, data) =>
    Api.fetchPut(
      `${API_CONFIG.ENDPOINT_RESERVATION}/alcohol_check_results/${path}/${id}`,
      data
    ).then(Api.toJson),
  createReservation: (where) =>
    Api.fetchPost(`${API_CONFIG.ENDPOINT_RESERVATION}`, where).then(Api.toJson),
};

export const questionnaireApi = {
  findById: (id) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_QUESTIONNAIRE}?reservationId=${id}`).then(Api.toJson),
};

export const reEnergyApi = {
  findOne: (where) => Api.fetchGet(`${API_CONFIG.ENDPOINT_REENERGY}`, where).then(Api.toJson),
};

export const chargeApi = {
  findAll: (where) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_CHARGE}/unit_info`, where).then(Api.toJson),
  findUsageCharge: (where) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_CHARGE}/usage_charge`, where).then(Api.toJson),
};

export const insuranceApi = {
  findByReservationStartDate: (where) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_INSURANCE}/info`, where).then(Api.toJson),
};

export const reservationStartApi = {
  usingStart: (id, version) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_RESERVATION_START}/${id}`, version),
};

export const reservationStopApi = {
  usingStop: (id, version) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_RESERVATION_STOP}/${id}`, version),
};

export const reservationCancelApi = {
  reservationCancel: (id, where) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_RESERVATION_CANCEL}/${id}`, where),
};

export const reservationExtentionTimeApi = {
  extend: (id, where) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_RESERVATION_EXTENTION_TIME}/${id}`, where),
};

export const promotionApi = {
  findAll: (where) => Api.fetchGet(`${API_CONFIG.ENDPOINT_PROMOTION}/list`, where).then(Api.toJson),
  findById: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_PROMOTION}/${id}`).then(Api.toJson),
  create: (data) => Api.fetchPost(API_CONFIG.ENDPOINT_PROMOTION, data).then(Api.toJson),
  update: (data) => Api.fetchPut(API_CONFIG.ENDPOINT_PROMOTION, data).then(Api.toJson),
  delete: (id) => Api.fetchDelete(`${API_CONFIG.ENDPOINT_PROMOTION}/${id}`).then(Api.toJson),
};

export const notificationSettingApi = {
  findAll: (where) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_NOTIFICATION_SETTING}/list`, where).then(Api.toJson),
  findById: (id) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_NOTIFICATION_SETTING}/${id}`).then(Api.toJson),
  getSignature: (data) =>
    Api.fetchGet(`${API_CONFIG.ENDPOINT_NOTIFICATION_SETTING}/signature_param`, data).then(
      Api.toJson
    ),
  createNotification: (data) =>
    Api.fetchPost(`${API_CONFIG.ENDPOINT_NOTIFICATION_SETTING}`, data).then(Api.toJson),
  updateNotification: (data) =>
    Api.fetchPut(`${API_CONFIG.ENDPOINT_NOTIFICATION_SETTING}`, data).then(Api.toJson),
  deleteNotification: (id) =>
    Api.fetchDelete(`${API_CONFIG.ENDPOINT_NOTIFICATION_SETTING}/${id}`).then(Api.toJson),
  testSend: (data) =>
    Api.fetchPost(`${API_CONFIG.ENDPOINT_NOTIFICATION_SETTING}/test_send`, data).then(Api.toJson),
};

export const groupApi = {
  findAll: (where) => Api.fetchGet(`${API_CONFIG.ENDPOINT_GROUP}/list`, where).then(Api.toJson),
  findById: (id) => Api.fetchGet(`${API_CONFIG.ENDPOINT_GROUP}/${id}`).then(Api.toJson),
  downloadCsv: (where) => Api.makeGetUrl(`${API_CONFIG.ENDPOINT_GROUP}/csv`, where),
  create: (data) => Api.fetchPost(API_CONFIG.ENDPOINT_GROUP, data).then(Api.toJson),
  update: (data) => Api.fetchPut(API_CONFIG.ENDPOINT_GROUP, data).then(Api.toJson),
  delete: (id) => Api.fetchDelete(`${API_CONFIG.ENDPOINT_GROUP}/${id}`).then(Api.toJson),
};

export const sharedFileApi = {
  findAll: (where) => Api.fetchGet(API_CONFIG.ENDPOINT_SHARED_FILE_LIST, where).then(Api.toJson),
  downloadFile: (where) => Api.makeGetUrl(API_CONFIG.ENDPOINT_DOWNLOAD_SHARED_FILE, where),
};
