<template>
  <div class="wrapper">
    <el-footer height="30px">Copyright REXEV Inc. All rights reserved.</el-footer>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrapper {
  width: 100% !important;
  flex: 1 0 auto;
  margin: 10px 0 0 0 !important;
  display: flex;
  justify-content: flex-end;
}
.el-footer {
  color: white;
  background-color: var(--color-primary);
  text-align: center;
  line-height: 30px;
  width: 100%;
  margin-top: auto;
}
</style>
