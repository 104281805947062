import is from 'is_js';
import _ from 'lodash';
import moment from 'moment';

// 汎用
const YYYYMM = value => value ? moment(value).format('YYYY/MM') : '';
const YYYYMMDD = value => value ? moment(value).format('YYYY/MM/DD') : '';
const YYYYMMDDHHmm = value => value ? moment(value).format('YYYY/MM/DD HH:mm') : '';
const YYYYMMDDHHmmss = value => value ? moment(value).format('YYYY/MM/DD HH:mm:ss') : '';
const HHmm = value => value ? moment(value, 'HH:mm:ss').format('HH:mm') : '';
const joinComma = list => _.join(list, ', ');
const joinSpace = list => _.join(list, ' ');
const lineBreak = value => _.replace(value, new RegExp(",", "g"), "\n")

// カンマ表示
const AddComma = value => ((value && is.number(Number(value))) ? Number(value).toLocaleString() : value);

export default {
  YYYYMM,
  YYYYMMDD,
  YYYYMMDDHHmm,
  YYYYMMDDHHmmss,
  HHmm,
  AddComma,
  joinComma,
  joinSpace,
  lineBreak,
};
