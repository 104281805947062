<template>
  <div id="header">
    <el-container>
      <span class="logo-wrapper" @click="goHome">
        <el-container>
          <el-aside width="125px">
            <img class="logo" src="/img/logo.png" />
          </el-aside>
        </el-container>
      </span>
      <el-container v-if="isLogin">
        <el-aside class="toggle" width="80px">
          <el-button size="small" @click.prevent="toggle">
            <el-icon v-if="isCollapse"><Plus /></el-icon>
            <el-icon v-else><Minus /></el-icon>
          </el-button>
        </el-aside>
        <el-container>
          <el-header style="text-align: right" height="60px">
            <span style="font-size: 0.9em; margin-right: 30px">{{ session.corpName }}</span>
            <font-awesome-icon icon="user-tie" size="lg" style="margin-right: 10px" />
            <span style="font-size: 0.9em; margin-right: 10px"
              >{{ session.lastName }} {{ session.firstName }}</span
            >
            <el-link type="primary" @click.stop.prevent="logout">ログアウト</el-link>
          </el-header>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Config from "@/conf/Config";
import { MUTATION_TYPES } from "@/store";

export default {
  name: "HeaderLayout",
  methods: {
    goHome() {
      this.$router.push("/");
    },
    toggle() {
      this.$store.commit(MUTATION_TYPES.SET_COLLAPSE, !this.isCollapse);
    },
    logout() {
      this.$router.push(Config.URL.LOGOUT_PATH);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#header {
  font-size: 1.1em;
  height: 60px;
  background-color: #f5f7fa;
  border-bottom: 1px solid #e4e7ed;
}
.logo-wrapper {
  cursor: pointer;
  width: 125px;
  margin: 4px 24px 4px 10px;
  cursor: pointer;
  border-radius: 3px;
}
.logo-wrapper:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 6px rgba(0, 0, 0, 0.08);
}
#header .logo {
  width: 125px;
  height: auto;
  display: block;
  margin: 5px auto;
}
#header .toggle {
  margin-left: 6px;
}
.el-aside {
  text-align: left;
  line-height: 60px;
}
.el-header {
  line-height: 60px;
}
</style>
