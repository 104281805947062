import Config from "@/conf/Config";
import store from "@/store";
import { SESSION_MUTATION_TYPES } from "@/store/modules/session";
import { MASTER_MUTATION_TYPES } from "@/store/modules/master";
import { ElMessageBox } from "element-plus";

const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

const poolData = POOL_DATA || {};
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

export default class Auth {
  static getUserPool() {
    return userPool;
  }

  static isLogin() {
    return userPool.getCurrentUser() != null;
  }

  static getCurrentUser() {
    return userPool.getCurrentUser();
  }

  static signin(Username, Password) {
    const userData = {
      Username,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
      Username,
      Password,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          resolve(result);
        },

        onFailure: (err) => {
          reject(err);
        },
        mfaRequired: () => {
          // 再送信する場合
          // cognitoUser.getAttributeVerificationCode()
          // console.log(ChallengeParameters, cognitoUser);
          ElMessageBox.prompt(
            "登録済みの電話番号宛にSMSを送信しました。メッセージ内に記載された認証コードを入力してください。",
            "認証コードの入力",
            {
              confirmButtonText: "送信",
              cancelButtonText: "戻る",
              inputErrorMessage: "6桁の数値で入力してください",
              inputPattern: /^([0-9]{6})$/,
              type: "info",
              showCancelButton: true,
              showConfirmButton: true,
              closeOnClickModal: false,
              showClose: false,
            }
          )
            .then((verificationCode) => {
              // console.log(verificationCode);
              cognitoUser.sendMFACode(verificationCode.value, {
                onSuccess: (session) => {
                  resolve(session);
                },
                onFailure: (err) => {
                  reject(err);
                },
              });
            })
            .catch((err) => {
              reject(err);
            });
        },
      });
    });
  }

  static signout() {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      store.commit(SESSION_MUTATION_TYPES.CLEAR_VALUES);
      store.commit(MASTER_MUTATION_TYPES.CLEAR_VALUES);
      cognitoUser.signOut();
    }
  }

  static getSession() {
    const cognitoUser = userPool.getCurrentUser();

    return new Promise((resolve, reject) => {
      if (cognitoUser == null) resolve(false);
      cognitoUser.getSession((err, session) => {
        if (err) reject(err);
        resolve(session);
      });
    });
  }

  static changePassword(oldPassword, newPassword) {
    const cognitoUser = userPool.getCurrentUser();

    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err) => {
        if (err) reject(err);
        cognitoUser.changePassword(oldPassword, newPassword, (error, result) => {
          if (error) reject(error);
          resolve(result);
        });
      });
    });
  }

  static resetPassword(Username) {
    const userData = {
      Username,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: () => {},
        onFailure: (err) => {
          reject(err);
        },
        async inputVerificationCode() {
          resolve(cognitoUser);
        },
      });
    });
  }

  static updateAttributes(attributes) {
    const cognitoUser = userPool.getCurrentUser();

    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err) => {
        if (err) reject(err);
        cognitoUser.updateAttributes(attributes, (error, results) => {
          if (error) reject(error);

          resolve(results);
        });
      });
    });
  }

  static getAttribute() {
    const cognitoUser = userPool.getCurrentUser();

    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err) => {
        if (err) reject(err);
        cognitoUser.getUserAttributes((error, results) => {
          if (error) reject(error);
          let attribute = {};
          results.forEach(({ Name, Value }) => {
            attribute = { ...attribute, [Name]: Value };
          });
          resolve(attribute);
        });
      });
    });
  }

  static isActivate() {
    const cognitoUser = userPool.getCurrentUser();

    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err) => {
        if (err) reject(err);
        cognitoUser.getUserAttributes((error, results) => {
          if (error) reject(error);
          resolve(results.some(({ Name }) => Name === Config.COGNITO.ATTRIBUTE_ACTIVATE));
        });
      });
    });
  }
}
