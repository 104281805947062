/* eslint-disable */

import is from "is_js";
import _ from "lodash";
import moment from "moment";
import "moment/locale/ja";
import logger from "@/module/Logger";
import Application from "@/views/base/Application";
import { ElMessageBox } from "element-plus";

export default {
  install: (app) => {
    app.mixin(Application);
    //   app.mixin(
    //   {
    //   mixins: [Application],
    // });

    moment.locale("ja");
    app.config.globalProperties.$moment = moment;
    app.config.globalProperties.$is = is;
    app.config.globalProperties.$_ = _;
    app.config.globalProperties.$logger = logger;
    app.config.globalProperties.$rexev = {};
    // 画面固有文言設定用（HTML許可）
    app.config.globalProperties.$rexev.warn = (message, title = "") =>
      ElMessageBox.confirm(message, title, {
        confirmButtonText: "OK",
        dangerouslyUseHTMLString: true,
        type: "warning",
        showCancelButton: false,
        showConfirmButton: true,
      });
    // APIのレスポンス表示用（文字列）
    app.config.globalProperties.$rexev.responseWarn = (message, title = "") =>
      ElMessageBox.confirm(message, title, {
        confirmButtonText: "OK",
        dangerouslyUseHTMLString: false,
        type: "warning",
        showCancelButton: false,
        showConfirmButton: true,
        customClass: "break",
      });
  },
};
