/*
 * メニューカテゴリ一覧
 *
 * 許可リスト方式
 * 全てのロールに許可する画面は "all" を指定
 */
import Config from "@/conf/Config";

export default [
  {
    category: "RESERVATION",
    icon: "clock",
    order: 1,
    name: "予約管理",
    roles: [
      Config.ROLE.ADMIN,
      Config.ROLE.CARSHARE_CORP,
      Config.ROLE.CARSHARE_USE_CORP,
      Config.ROLE.CLEANING_CORP,
      Config.ROLE.MAINTENANCE_CORP
    ]
  },
  {
    category: "CAR",
    icon: "car",
    order: 2,
    name: "車両管理",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP,Config.ROLE.CARSHARE_USE_CORP, Config.ROLE.CLEANING_CORP, Config.ROLE.MAINTENANCE_CORP, Config.ROLE.ENERGY_MANAGEMENT_CORP]
  },
  {
    category: "EV",
    icon: "charging-station",
    order: 3,
    name: "ステーション管理",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP, Config.ROLE.ENERGY_MANAGEMENT_CORP]
  },
  {
    category: "MEMBER",
    icon: "user",
    order: 4,
    name: "会員管理",
    roles: [
      Config.ROLE.ADMIN,
      Config.ROLE.CARSHARE_CORP,
      Config.ROLE.CARSHARE_USE_CORP,
      Config.ROLE.CLEANING_CORP,
      Config.ROLE.MAINTENANCE_CORP
    ]
  },
  {
    category: "CORPORATION",
    icon: "building",
    order: 5,
    name: "法人管理",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP, Config.ROLE.ENERGY_MANAGEMENT_CORP]
  },
  // {
  //   category: "CHARGE",
  //   icon: "burn",
  //   order: 6,
  //   name: "外部充電所管理",
  //   roles: [Config.ROLE.ADMIN]
  // },
  {
    category: "USER",
    icon: "user-tie",
    order: 7,
    name: "ユーザ管理",
    roles: [Config.ROLE.ALL]
  },
  {
    category: "PROMOTION",
    icon: "gift",
    order: 8,
    name: "プロモーション管理",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP]
  },
  {
    category: "NOTIFICATION",
    icon: "envelope",
    order: 9,
    name: "お知らせ管理",
    roles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP]
  },
  {
    category: "FILE",
    icon: "file",
    order: 10,
    name: "マニュアルダウンロード",
    roles: [Config.ROLE.ALL]
  }
];
