import is from "is_js";
import { createRouter, createWebHistory } from "vue-router";
import Config from "@/conf/Config";
import Statistics from "@/module/Statistics";
import store, { MUTATION_TYPES } from "@/store";
import { SESSION_GETTER_TYPES } from "@/store/modules/session";
import { buildPath } from "@/helpers";
import { isEmpty, isEmptyObject } from "@/helpers/validators";
import ErrorTracking from "@/module/ErrorTracking";
import Auth from "@/module/Auth";
import logger from "@/module/Logger";

/* namespace */
const NAMESPACE_USER = "user";
const NAMESPACE_SCHEDULE = "schedule";
const NAMESPACE_CORPORATION = "corporation";
const NAMESPACE_CORP_FRAME_CYCLE = "corpFrameCycle";
const NAMESPACE_EV = "ev";
const NAMESPACE_VEHICLE = "vehicle";
const NAMESPACE_MEMBER = "member";
const NAMESPACE_RESERVATION = "reservation";
const NAMESPACE_CORP_FRAME = "corpFrame";
const NAMESPACE_PROMOTION = "promotion";
const NAMESPACE_NOTIFICATION_SETTING = "notificationSetting";
const NAMESPACE_GROUP = "group";
const NAMESPACE_FILE = "sharedFile";

const USER = buildPath(NAMESPACE_USER);
const SCHEDULE = buildPath(NAMESPACE_SCHEDULE);
const CORPORATION = buildPath(NAMESPACE_CORPORATION);
const CORP_FRAME_CYCLE = buildPath(NAMESPACE_CORP_FRAME_CYCLE);
const EV = buildPath(NAMESPACE_EV);
const VEHICLE = buildPath(NAMESPACE_VEHICLE);
const MEMBER = buildPath(NAMESPACE_MEMBER);
const RESERVATION = buildPath(NAMESPACE_RESERVATION);
const CORP_FRAME = buildPath(NAMESPACE_CORP_FRAME);
const PROMOTION = buildPath(NAMESPACE_PROMOTION);
const NOTIFICATION = buildPath(NAMESPACE_NOTIFICATION_SETTING);
const GROUP = buildPath(NAMESPACE_GROUP);
const FILE = buildPath(NAMESPACE_FILE);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    let result;
    if (savedPosition) {
      result = savedPosition;
    } else if (to.path === from.path) {
      result = null;
    } else {
      result = { x: 0, y: 0 };
    }
    return result;
  },
  routes: [
    {
      path: Config.URL.SESSION_TIMEOUT_PATH,
      name: "SessionTimeOut",
      component: () => import(/* webpackChunkName: "about" */ "@/views/error/SessionTimeOut.vue"),
      meta: {
        requiresAuth: false,
        title: "セッションタイムアウト",
      },
    },
    {
      path: Config.URL.BAD_REQUEST_PATH,
      name: "BadRequest",
      component: () => import(/* webpackChunkName: "about" */ "@/views/error/BadRequest.vue"),
      meta: {
        requiresAuth: false,
        title: "不正な画面遷移",
      },
    },
    {
      path: Config.URL.SYSERR_PATH,
      name: "SystemError",
      component: () => import(/* webpackChunkName: "about" */ "@/views/error/SystemError.vue"),
      meta: {
        requiresAuth: false,
        title: "予期せぬエラー",
      },
    },
    {
      path: Config.URL.LOGIN_PATH,
      name: "Login",
      component: () => import(/* webpackChunkName: "about" */ "@/views/login/Login.vue"),
      meta: {
        requiresAuth: false,
        title: "ログイン",
      },
    },
    {
      path: Config.URL.LOGOUT_PATH,
      name: "Logout",
      component: () => import(/* webpackChunkName: "about" */ "@/views/login/Logout.vue"),
      meta: {
        requiresAuth: false,
        title: "ログアウト",
      },
    },
    // todo 開発終了後の取り扱い
    {
      path: "/",
      name: "Index",
      component: () => import(/* webpackChunkName: "about" */ "@/views/index/Index.vue"),
      meta: {
        requiresAuth: false,
        title: "ポータルトップ",
      },
    },
    {
      path: USER.LIST,
      name: "UserList",
      component: () => import(/* webpackChunkName: "about" */ "@/views/user/UserList.vue"),
      meta: {
        requiresAuth: true,
        title: "ユーザ一覧",
        allowRoles: [Config.ROLE.ALL],
      },
    },
    {
      path: USER.EDIT,
      name: "UserEdit",
      component: () => import(/* webpackChunkName: "about" */ "@/views/user/UserEdit.vue"),
      meta: {
        requiresAuth: true,
        title: "ユーザ登録・変更",
        allowRoles: [Config.ROLE.ALL],
      },
    },
    {
      path: USER.EDIT_COMPLETE,
      name: "UserEditComplete",
      component: () => import(/* webpackChunkName: "about" */ "@/views/common/Complete.vue"),
      meta: {
        requiresAuth: true,
        title: "ユーザ登録・変更完了",
        allowRoles: [Config.ROLE.ALL],
      },
    },
    {
      path: SCHEDULE.LIST,
      name: "scheduleList",
      component: () => import(/* webpackChunkName: "about" */ "@/views/schedule/ScheduleList.vue"),
      meta: {
        requiresAuth: true,
        title: "予約カレンダー",
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.CARSHARE_USE_CORP,
          Config.ROLE.CLEANING_CORP,
          Config.ROLE.MAINTENANCE_CORP,
        ],
      },
    },
    {
      path: RESERVATION.LIST,
      name: "reservationList",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/reservation/ReservationList.vue"),
      meta: {
        requiresAuth: true,
        title: "予約一覧",
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.CARSHARE_USE_CORP,
          Config.ROLE.CLEANING_CORP,
          Config.ROLE.MAINTENANCE_CORP,
        ],
      },
    },
    {
      path: RESERVATION.EDIT,
      name: "reservationEdit",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/reservation/ReservationEdit.vue"),
      meta: {
        requiresAuth: true,
        title: "予約登録・変更",
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.CARSHARE_USE_CORP,
          Config.ROLE.CLEANING_CORP,
          Config.ROLE.MAINTENANCE_CORP,
        ],
      },
    },
    {
      path: "/reservationEditConfirm",
      name: "reservationEditConfirm",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/reservation/ReservationEditConfirm.vue"),
      meta: {
        requiresAuth: true,
        title: "予約登録・変更確認",
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.CARSHARE_USE_CORP,
          Config.ROLE.CLEANING_CORP,
          Config.ROLE.MAINTENANCE_CORP,
        ],
      },
    },
    {
      path: RESERVATION.EDIT_COMPLETE,
      name: "reservationEditComplete",
      component: () => import(/* webpackChunkName: "about" */ "@/views/common/Complete.vue"),
      meta: {
        requiresAuth: true,
        title: "予約登録・変更完了",
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.CARSHARE_USE_CORP,
          Config.ROLE.CLEANING_CORP,
          Config.ROLE.MAINTENANCE_CORP,
        ],
      },
    },
    {
      path: "/vehicle/:id",
      name: "VehicleDetail",
      component: () => import(/* webpackChunkName: "about" */ "@/views/vehicle/VehicleDetail.vue"),
      meta: {
        requiresAuth: true,
        title: "車両詳細",
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.ENERGY_MANAGEMENT_CORP,
        ],
      },
    },
    {
      path: VEHICLE.EDIT,
      name: "VehicleEdit",
      component: () => import(/* webpackChunkName: "about" */ "@/views/vehicle/VehicleEdit.vue"),
      meta: {
        requiresAuth: true,
        title: "車両登録・変更",
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.ENERGY_MANAGEMENT_CORP,
        ],
      },
    },
    {
      path: VEHICLE.EDIT_COMPLETE,
      name: "VehicleEditComplete",
      component: () => import(/* webpackChunkName: "about" */ "@/views/common/Complete.vue"),
      meta: {
        requiresAuth: true,
        title: "車両登録・変更完了",
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.ENERGY_MANAGEMENT_CORP,
        ],
      },
    },
    {
      path: VEHICLE.LIST,
      name: "VehicleList",
      component: () => import(/* webpackChunkName: "about" */ "@/views/vehicle/VehicleList.vue"),
      meta: {
        requiresAuth: true,
        title: "車両一覧",
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.CARSHARE_USE_CORP,
          Config.ROLE.CLEANING_CORP,
          Config.ROLE.MAINTENANCE_CORP,
          Config.ROLE.ENERGY_MANAGEMENT_CORP,
        ],
      },
    },
    {
      path: EV.LIST,
      name: "EvList",
      component: () => import(/* webpackChunkName: "about" */ "@/views/ev/EvList.vue"),
      meta: {
        requiresAuth: true,
        title: "ステーション一覧",
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.ENERGY_MANAGEMENT_CORP,
        ],
      },
    },
    {
      path: EV.EDIT,
      name: "EvEdit",
      component: () => import(/* webpackChunkName: "about" */ "@/views/ev/EvEdit.vue"),
      meta: {
        requiresAuth: true,
        title: "ステーション登録・変更",
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.ENERGY_MANAGEMENT_CORP,
        ],
      },
    },
    {
      path: EV.EDIT_COMPLETE,
      name: "EvEditComplete",
      component: () => import(/* webpackChunkName: "about" */ "@/views/common/Complete.vue"),
      meta: {
        requiresAuth: true,
        title: "ステーション登録・変更完了",
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.ENERGY_MANAGEMENT_CORP,
        ],
      },
    },
    {
      path: CORPORATION.LIST,
      name: "corporationList",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/corporation/CorporationList.vue"),
      meta: {
        requiresAuth: true,
        title: "法人一覧",
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.ENERGY_MANAGEMENT_CORP,
        ],
      },
    },
    {
      path: CORPORATION.EDIT_COMPLETE,
      name: "CorporationEditComplete",
      component: () => import(/* webpackChunkName: "about" */ "@/views/common/Complete.vue"),
      meta: {
        requiresAuth: true,
        title: "法人登録・変更完了",
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.ENERGY_MANAGEMENT_CORP,
        ],
      },
    },
    {
      path: CORPORATION.EDIT,
      name: "corporationEdit",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/corporation/CorporationEdit.vue"),
      meta: {
        requiresAuth: true,
        title: "法人登録・変更",
        useCustomTitle: true,
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.ENERGY_MANAGEMENT_CORP,
        ],
      },
    },
    {
      path: CORP_FRAME_CYCLE.LIST,
      name: "corpFrameCycleList",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/corpFrame/CorpFrameCycleList.vue"),
      meta: {
        requiresAuth: true,
        title: "法人枠作成サイクル一覧",
        allowRoles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP],
      },
    },
    {
      path: CORP_FRAME_CYCLE.EDIT,
      name: "corpFrameCycleEdit",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/corpFrame/CorpFrameCycleEdit.vue"),
      meta: {
        requiresAuth: true,
        title: "法人枠作成サイクル登録・変更",
        allowRoles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP],
      },
    },
    {
      path: CORP_FRAME_CYCLE.EDIT_COMPLETE,
      name: "corpFrameCycleEditComplete",
      component: () => import(/* webpackChunkName: "about" */ "@/views/common/Complete.vue"),
      meta: {
        requiresAuth: true,
        title: "法人枠作成サイクル登録・変更完了",
        allowRoles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP],
      },
    },
    {
      path: MEMBER.LIST,
      name: "MemberList",
      component: () => import(/* webpackChunkName: "about" */ "@/views/member/MemberList.vue"),
      meta: {
        requiresAuth: true,
        title: "会員一覧",
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.CARSHARE_USE_CORP,
          Config.ROLE.CLEANING_CORP,
          Config.ROLE.MAINTENANCE_CORP,
        ],
      },
    },
    {
      path: MEMBER.EDIT,
      name: "MemberEdit",
      component: () => import(/* webpackChunkName: "about" */ "@/views/member/MemberEdit.vue"),
      meta: {
        requiresAuth: true,
        title: "会員変更",
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.CARSHARE_USE_CORP,
          Config.ROLE.CLEANING_CORP,
          Config.ROLE.MAINTENANCE_CORP,
        ],
      },
    },
    {
      path: MEMBER.EDIT_COMPLETE,
      name: "MemberEditComplete",
      component: () => import(/* webpackChunkName: "about" */ "@/views/common/Complete.vue"),
      meta: {
        requiresAuth: true,
        title: "会員変更完了",
        allowRoles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP],
      },
    },
    {
      path: MEMBER.INVITE,
      name: "MemberInvitation",
      component: () => import(/* webpackChunkName: "about" */ "@/views/member/MemberInvite.vue"),
      meta: {
        requiresAuth: true,
        title: "法人会員招待メール送信",
        allowRoles: [
          Config.ROLE.ADMIN,
          Config.ROLE.CARSHARE_CORP,
          Config.ROLE.CARSHARE_USE_CORP,
          Config.ROLE.CLEANING_CORP,
          Config.ROLE.MAINTENANCE_CORP,
        ],
      },
    },
    {
      path: CORP_FRAME.EDIT,
      name: "CorpFrameEdit",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/corpFrame/CorpFrameEdit.vue"),
      meta: {
        requiresAuth: true,
        title: "法人枠登録・変更",
        allowRoles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP],
      },
    },
    {
      path: CORP_FRAME.EDIT_COMPLETE,
      name: "CorpFrameEditComplete",
      component: () => import(/* webpackChunkName: "about" */ "@/views/common/Complete.vue"),
      meta: {
        requiresAuth: true,
        title: "法人枠登録・変更完了",
        allowRoles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP],
      },
    },
    {
      path: PROMOTION.LIST,
      name: "promotionList",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/promotion/PromotionList.vue"),
      meta: {
        requiresAuth: true,
        title: "プロモーション一覧",
        allowRoles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP],
      },
    },
    {
      path: PROMOTION.EDIT,
      name: "promotionEdit",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/promotion/PromotionEdit.vue"),
      meta: {
        requiresAuth: true,
        title: "プロモーション登録・変更",
        allowRoles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP],
      },
    },
    {
      path: PROMOTION.EDIT_COMPLETE,
      name: "promotionEditComplete",
      component: () => import(/* webpackChunkName: "about" */ "@/views/common/Complete.vue"),
      meta: {
        requiresAuth: true,
        title: "プロモーション登録・変更完了",
        allowRoles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP],
      },
    },
    {
      path: NOTIFICATION.LIST,
      name: "NotificationSettingList",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/notification/NotificationSettingList.vue"),
      meta: {
        requiresAuth: true,
        title: "お知らせ設定一覧",
        allowRoles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP],
      },
    },
    {
      path: NOTIFICATION.EDIT,
      name: "NotificationSettingEdit",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/notification/NotificationSettingEdit.vue"),
      meta: {
        requiresAuth: true,
        title: "お知らせ設定登録・変更",
        allowRoles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP],
      },
    },
    {
      path: NOTIFICATION.EDIT_COMPLETE,
      name: "NotificationEditComplete",
      component: () => import(/* webpackChunkName: "about" */ "@/views/common/Complete.vue"),
      meta: {
        requiresAuth: true,
        title: "お知らせ設定登録・変更完了",
        allowRoles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP],
      },
    },
    {
      path: GROUP.LIST,
      name: "groupList",
      component: () => import(/* webpackChunkName: "about" */ "@/views/group/GroupList.vue"),
      meta: {
        requiresAuth: true,
        title: "グループ一覧",
        allowRoles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP],
      },
    },
    {
      path: GROUP.EDIT,
      name: "groupEdit",
      component: () => import(/* webpackChunkName: "about" */ "@/views/group/GroupEdit.vue"),
      meta: {
        requiresAuth: true,
        title: "グループ登録・変更",
        allowRoles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP],
      },
    },
    {
      path: GROUP.EDIT_COMPLETE,
      name: "groupEditComplete",
      component: () => import(/* webpackChunkName: "about" */ "@/views/common/Complete.vue"),
      meta: {
        requiresAuth: true,
        title: "グループ登録・変更完了",
        allowRoles: [Config.ROLE.ADMIN, Config.ROLE.CARSHARE_CORP],
      },
    },
    {
      path: FILE.DOWNLOAD,
      name: "sharedFileDownload",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/sharedFile/SharedFileDownload.vue"),
      meta: {
        requiresAuth: true,
        title: "ファイルダウンロード",
        allowRoles: [Config.ROLE.ALL],
      },
    },
    // {
    //   path: FILE.UPLOAD,
    //   name: "sharedFileUpLoad",
    //   component: () => import(/* webpackChunkName: "about" */ "@/views/sharedFile/SharedFileUpload.vue"),
    //   meta: {
    //     requiresAuth: true,
    //     title: "ファイルアップロード",
    //     allowRoles: [Config.ROLE.ALL]
    //   }}
  ],
});

router.beforeEach(async (to, from, next) => {
  logger.info("__beforeEach__");
  if (!to.meta.useCustomTitle) {
    document.title = `${to.meta.title} ｜ eMMS`;
    if (isEmpty(to.meta.title)) {
      // ルーティングなし
      logger.info("nothing to route table");
      next(Config.URL.BAD_REQUEST_PATH);
      return;
    }
  }
  const session = store.getters[SESSION_GETTER_TYPES.VALUES];
  if (to.meta.requiresAuth) {
    // 未ログイン時はログインページへリダイレクト
    if (isEmpty(session) || isEmptyObject(session)) {
      logger.info("Redirect to Login Form");
      sessionStorage.setItem(
        Config.REDIRECT_URL,
        JSON.stringify({ path: to.path, query: to.query })
      ); // redirect settings
      next(Config.URL.LOGIN_PATH);
      return;
    }

    // 権限エラーのハンドリング
    if (
      !to.meta.allowRoles.includes(Config.ROLE.ALL) &&
      !to.meta.allowRoles.some((role) => session.roles.includes(role))
    ) {
      logger.info("UnAuthorize Action");
      next(Config.URL.BAD_REQUEST_PATH);
      return;
    }

    // MFA設定がONの場合は電話番号の登録を強制する
    if (
      session.adminMfaEnabled &&
      isEmpty(session.phoneNumber) &&
      to.fullPath !== `/userEdit?id=${session.id}` &&
      to.path !== "/userEditComplete"
    ) {
      next(`/userEdit?id=${session.id}`);
      return;
    }

    // セッション確認 (=token refresh)
    Auth.getSession()
      .then((res) => {
        if (is.not.empty(session) && res.isValid()) {
          logger.info("session available!");
          const refreshToken = res.getRefreshToken();
          store.commit(MUTATION_TYPES.SET_REFRESH_TOKEN, refreshToken.token);
        } else {
          next(Config.URL.SESSION_TIMEOUT_PATH);
        }
      })
      .catch(() => {
        next(Config.URL.SESSION_TIMEOUT_PATH);
      });
  }
  if (from.path !== to.path) {
    logger.info(`Screen transition. from=(${from.path}) to=(${to.path})`, { id: session.username });
    const statistics = new Statistics();
    statistics.setAccessLog(to.path);
  }
  next();
});

// router.afterEach((to, from) => {
// });

router.onError((error) => {
  ErrorTracking.captureException(error);
  ErrorTracking.showReportDialog();
});

export default router;
