import { mapGetters } from "vuex";
import SimpleLayouts from "@/components/layouts/SimpleLayouts.vue";
import Config from "@/conf/Config";
import { GETTER_TYPES } from "@/store";
import { SESSION_GETTER_TYPES } from "@/store/modules/session";
import { MASTER_GETTER_TYPES, MASTER_MUTATION_TYPES } from "@/store/modules/master";
import { masterApi } from "@/module/Api";
import MenuCategoryList from "@/conf/MenuCategoryList";
import MenuList from "@/conf/MenuList";
import ErrorHandler from "@/module/ErrorHandler";
import Auth from "@/module/Auth";
import filter from "@/module/filter";

/*
 * グローバル共通処理
 */
export default {
  // beforeRouteEnter(to, from, next) {
  //  next((vm) => {});
  // },
  components: {
    SimpleLayouts,
  },

  beforeRouteLeave(to, from, next) {
    next();
  },

  created() {
    if (this.hasState && this.isLogin) {
      this.setMasterInfo();
    }
  },

  methods: {
    async setMasterInfo() {
      if (this.$options.name === "Navbar") {
        // キャッシュ可のマスタをキャッシュする
        if (this.$is.empty(this.codeCategories)) {
          const response = await masterApi.getCodeCategory().catch(ErrorHandler.apiHandleErr);
          this.$store.commit(MASTER_MUTATION_TYPES.SET_CODE_CATEGORIES, response.data);
        }
      }
    },
    getTargetList: (targetList, roles) =>
      targetList.filter((row) =>
        row.roles.some((role) => role === Config.ROLE.ALL || roles.includes(role))
      ),
    go(url) {
      this.$router.push(url);
    },
    deepEqual(obj1, obj2) {
      if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
        return obj1 === obj2;
      }

      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return false;
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const key of keys1) {
        if (!keys2.includes(key) || String(obj1[key]) !== String(obj2[key])) {
          return false;
        }
      }

      return true;
    },
    // filterはveu3で使用不可になったため共通メソッドとする
    YYYYMM: filter.YYYYMM,
    YYYYMMDD: filter.YYYYMMDD,
    YYYYMMDDHHmm: filter.YYYYMMDDHHmm,
    YYYYMMDDHHmmss: filter.YYYYMMDDHHmmss,
    HHmm: filter.HHmm,
    AddComma: filter.AddComma,
    joinComma: filter.joinComma,
    joinSpace: filter.joinSpace,
    lineBreak: filter.lineBreak,
  },

  computed: {
    ...mapGetters({
      session: SESSION_GETTER_TYPES.VALUES,
      processing: GETTER_TYPES.GET_PROCESSING,
      dirty: GETTER_TYPES.GET_DIRTY,
      isCollapse: GETTER_TYPES.GET_COLLAPSE,
      page: GETTER_TYPES.GET_PAGE,
      codeCategories: MASTER_GETTER_TYPES.CODE_CATEGORIES,
      sex: MASTER_GETTER_TYPES.SEX,
    }),
    isLogin() {
      return this.$is.not.empty(this.session) && Auth.isLogin();
    },
    appPrefix() {
      return Config.MY_APP_PREFIX;
    },
    hasState() {
      return this.$store !== undefined;
    }, // pluginはステートを持たない
    roles() {
      return this.session && this.session.roles ? this.session.roles : [];
    },
    menuCategories() {
      const categories = this.getTargetList(MenuCategoryList, this.roles);
      return this.$_.chain(categories).sortBy("order").value();
    },
    menus() {
      const menuList = this.getTargetList(MenuList, this.roles);
      return this.$_.chain(menuList).sortBy("order").value();
    },
    hasAdmin() {
      return this.roles.some((role) => role === Config.ROLE.ADMIN);
    },
    hasCarshareCorp() {
      return this.roles.some((role) => role === Config.ROLE.CARSHARE_CORP);
    },
    hasEnergyManagementCorp() {
      return this.roles.some((role) => role === Config.ROLE.ENERGY_MANAGEMENT_CORP);
    },
    hasSystemUsingCorp() {
      return this.roles.some(
        (role) =>
          role === Config.ROLE.CARSHARE_USE_CORP ||
          role === Config.ROLE.MAINTENANCE_CORP ||
          role === Config.ROLE.CLEANING_CORP
      );
    },
    isProduction() {
      return this.$store.state.mode === "production";
    },
    targetSorce() {
      return this.isLogin ? "メニュー" : "ログインページ";
    },
    currentPath() {
      // return this.$route.path;
      return this.$router.currentRoute.value.path;
    },
    currentQuery() {
      // return this.$route.query;
      return this.$router.currentRoute.value.query;
    },
    currentName() {
      // return this.$route.name;
      return this.$router.currentRoute.value.name;
    },
  },
};
