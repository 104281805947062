/* eslint-disable */
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import * as session from "@/store/modules/session";
import * as form from "@/store/modules/form";
import * as master from "@/store/modules/master";

export const GETTER_TYPES = {
  GET_REFRESH_TOKEN: "GET_REFRESH_TOKEN",
  GET_PROCESSING: "GET_PROCESSING",
  GET_DIRTY: "GET_DIRTY",
  GET_COLLAPSE: "GET_COLLAPSE",
  GET_PAGE: "GET_PAGE",
  GET_ERROR_MESSAGES: "GET_ERROR_MESSAGES",
  GET_RESERVATION_DATA: "GET_RESERVATION_DATA",
};

export const MUTATION_TYPES = {
  SET_REFRESH_TOKEN: "SET_REFRESH_TOKEN",
  SET_PROCESSING: "SET_PROCESSING",
  SET_DIRTY: "SET_DIRTY",
  SET_COLLAPSE: "SET_COLLAPSE",
  SET_PAGE: "SET_PAGE",
  SET_ERROR_MESSAGES: "SET_ERROR_MESSAGES",
  SET_RESERVATION_DATA: "SET_RESERVATION_DATA",
};

const store = createStore({
  modules: {
    session,
    master,
    form,
  },

  state: {
    refreshToken: null,
    processing: false,
    dirty: false,
    isCollapse: true,
    error_messages: [],
    page: 1,
    mode: process.env.NODE_ENV,
  },

  getters: {
    [GETTER_TYPES.GET_REFRESH_TOKEN](state) {
      return state.refreshToken;
    },
    [GETTER_TYPES.GET_PROCESSING](state) {
      return state.processing;
    },
    [GETTER_TYPES.GET_DIRTY](state) {
      return state.dirty;
    },
    [GETTER_TYPES.GET_COLLAPSE](state) {
      return state.isCollapse;
    },
    [GETTER_TYPES.GET_PAGE](state) {
      return state.page;
    },
    [GETTER_TYPES.GET_ERROR_MESSAGES](state) {
      return state.error_messages;
    },
    [GETTER_TYPES.GET_RESERVATION_DATA](state) {
      return state.data;
    },
  },

  mutations: {
    [MUTATION_TYPES.SET_REFRESH_TOKEN](state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    [MUTATION_TYPES.SET_PROCESSING](state, processing) {
      state.processing = processing;
    },
    [MUTATION_TYPES.SET_DIRTY](state, dirty) {
      state.dirty = dirty;
    },
    [MUTATION_TYPES.SET_COLLAPSE](state, isCollapse) {
      state.isCollapse = isCollapse;
    },
    [MUTATION_TYPES.SET_PAGE](state, page) {
      state.page = page;
    },
    [MUTATION_TYPES.SET_ERROR_MESSAGES](state, error_messages) {
      state.error_messages = error_messages;
    },
    [MUTATION_TYPES.SET_RESERVATION_DATA](state, data) {
      state.data = data;
    },
  },

  strict: process.env.NODE_ENV !== "production",
  plugins: [createPersistedState()],
});

export default store;
